<template>
  <div>
    <div class="mb-2 items-center">
      <vs-button class="ml-auto" @click="popupAddSkill = true; editMode = false" size="small">Add New Skill</vs-button>
    </div>
    <vx-card no-shadow>
      <vs-table
        :max-items="descriptionItems[0]"
        pagination
        :data="skills"
        description
        search
        :description-items="descriptionItems"
        description-title="Registries"
        description-connector="of"
        description-body="Pages">

        <template slot="header">
          <h5 class="flex items-center mb-2">
            <feather-icon icon="TagIcon" class="w-5 h-5 mr-1"></feather-icon>
            <span> Skills </span>
          </h5>
        </template>
        <template slot="thead">
          <vs-th>
            Name
          </vs-th>
          <vs-th>
            Action
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
            <vs-td :data="data[indextr].name">
              {{data[indextr].name}}
            </vs-td>

            <vs-td class="whitespace-no-wrap">
                <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click="editSkill(tr)" />
                <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteSkill(tr.id)" />
              </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <vs-popup class="holamundo" @close="initializeAll" :title="editMode ? 'Edit Skill':'Add New Skill'" :active.sync="popupAddSkill">
        <form>
          <div class="vx-row">
            <div class="vx-col w-full">
              <vs-input v-validate="{ required: true}" label="Name *" placeholder="Skill Name" name="skill_name" v-model="newSkill.name" class="w-full" />
              <span class="text-danger text-sm"  v-show="errors.has('skill_name')">{{ errors.first('skill_name') }}</span>
            </div>
          </div>
          <vs-button class="ml-auto mt-2" @click="editMode ? updateLanguage(): createSkill()" size="small">{{editMode ? 'Update':'Add Language'}}</vs-button>
        </form>
      </vs-popup>
    </vx-card>
  </div>
</template>

<script>

import vSelect from 'vue-select'
export default {
  components: {
    vSelect,
  },
  data() {
    return {
      descriptionItems: [5,15,50],
      newSkill: {
        name: "",
      },
      popupAddSkill: false,
      editMode: false,
      awaitingUpdate: "",
      awaitingDelete: "",
    }
  },
  computed: {
    skills(){
      return this.$store.state.skills
    }
  },
  methods: {
    createSkill(){
      let formData = new FormData()
      formData.append('data', JSON.stringify(this.newSkill))
      this.resquestProcessor(formData, 'create')
    },
    updateLanguage(){
      let formData = new FormData()
      formData.append('data', JSON.stringify(this.newSkill))
      this.resquestProcessor(formData,`${this.awaitingUpdate}/update`, 'UPDATE')
    },
    resquestProcessor(formData, action, ftn='ADD'){
      this.$vs.loading({color: "#444", type: "sound"})
      this.$http.post(`/skills/${action}`,formData)
      .then((response) => {
        this.$vs.loading.close()
        if(response.data.success){
          this.initializeAll()
          this.$store.commit(`${ftn}_SKILL`, response.data.data.skill)
        }
     })
    },
    fetchSkills() {
      this.$http.get(`/skills`)
        .then((response) => {
          if(response.data.success){
            this.$store.commit('SET_SKILLS', response.data.data.skills)
          }
        })
    },
    editSkill(skill){
      this.editMode = true
      this.newSkill =  {
        name: skill.name,
      }
      this.popupAddSkill = true
      this.awaitingUpdate = skill.id
    },
    deleteSkill(skillId){
      this.awaitingDelete = skillId
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Are you sure?',
        text: 'The selected skill will be removed!',
        accept: this.acceptDelete
      })
    },
    acceptDelete(){
      if(!this.awaitingDelete){return}
      this.$http.delete(`/skills/${this.awaitingDelete}/delete`)
      .then((response) =>{
        if(response.data.success){
          this.$store.commit('DELETE_SKILL', this.awaitingDelete)
          this.initializeAll();
        }
      })

    },
    initializeAll(){
      this.newSkill = {
        name: "",
      }
      this.popupAddSkill = false
      this.editMode = false
      this.awaitingUpdate = ""
      this.awaitingDelete = ""
      this.$validator.reset()
    }
  },
  created(){
     if(!this.skills.length){
      this.fetchSkills()
    }
  }
}
</script>
